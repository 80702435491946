$(document).ready(function () {

    // if (!$('#edit-reset').length) {
    //     $('.filters__toggle').removeClass('filters__toggle--hidden');
    // }
    //
    $(".filters__reset").on('click', function (e) {
        e.preventDefault();
        window.location.href = window.location.href.split('?')[0];
    });

    // $(".filters__reset").on('click', function () {
    //     $('.filters__hide').trigger('click');
    // });
    // $('.filters__hide').click(function () {
    //     $('.map__search .column-close').trigger('click');
    //     toggleMapSearchResults();
    // });
    //showArrows();

    $('.map-categories__button').on('click', function () {
        var currentId = $(this).attr('data-filter-id');
        toggleCategoryClass($(this));
        changeCategoryFilterForm(currentId);
        changeFilterColumnClass();
        //$scope.updateSize();
    });

    $('.map-column--first .column-close').on('click', function () {
        $(this).parents('.setPos').addClass('is-hidden');
        $('.map-categories > button').removeClass('is-active');
        $('.filters__hide').trigger('click');
        $scope.updateSize();
    });

});

function changeCategoryFilterForm(id) {
    var filter = $('.map-filter'),
        forms = filter.find('form');

    forms.each(function (index) {
        if ($(this).attr('data-form-id') === id) {
            $(this).addClass('is-visible');
        } else {
            $(this).removeClass('is-visible');
        }
    });
}

function toggleMapSearchResults() {
    var map = $('.map-content');
    var btn = $('.filters__hide');
    if (map.hasClass('map__search--active')) {
        btn.removeClass('filters__hide--hidden');
    } else {
        btn.addClass('filters__hide--hidden');
    }
}

function toggleCategoryClass(category) {

    category.parent().find('> button').removeClass('is-active');

    if (!category.hasClass('is-active')) {
        category.addClass('is-active');
    }
}

function changeFilterColumnClass() {
    var button = $('.map-categories > button.is-active');

    if (!button.length) {
        $('.map-column--filter').parent().addClass('is-hidden');
    } else {
        $('.map-column--filter').parent().removeClass('is-hidden');
    }
}

function addCategoryFilterClass() {
    var forms = $('.map-filter > form');

    var id = $('.map-categories > button.is-active').attr('data-filter-id');

    forms.each(function () {
        if ($(this).attr('data-form-id') === id) {
            $(this).addClass('is-visible');
        }

    });
}

// $(document).ready(function () {
//
//     if (!$('.filters__reset').length) {
//         $('.filters__toggle').removeClass('filters__toggle--hidden');
//     }
//
//     $(".filters__reset").on('click', function (e) {
//         e.preventDefault();
//         window.location.href = window.location.href.split('?')[0];
//     });
//
//     function changeIcon() {
//         var map = $('.map-content');
//         var btn = $('.filters__toggle');
//
//         if(map.hasClass('map__search--active')) {
//             btn.removeClass('filters__toggle--hidden');
//         } else {
//             btn.addClass('filters__toggle--hidden');
//         }
//     }
//
//     $('.filters__toggle').on('click', function() {
//         $('.map-search-toggle').trigger('click');
//         changeIcon();
//     });
//
//     $('.column-close').on('click', function() {
//         $('.result-map-search-toggle').trigger('click');
//         changeIcon();
//     });
//
//     $('.filters-btn-open').on('click', function() {
//         $(this).toggleClass('is-active');
//         $('.colPos90').toggleClass('-opened');
//     });
//
//     var searchResults = document.querySelector('.no-margin');
//
//     function searchResultatsCount() {
//         var numberOfSearchResults = document.querySelectorAll('.no-margin li').length,
//             countDiv = document.querySelector('.container-results');
//
//         countDiv.innerHTML = numberOfSearchResults + " ";
//     }
//
//     if (searchResults !== null) {
//         searchResultatsCount();
//     }
//
// });
