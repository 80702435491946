function removeNameAttribute(field) {
    if ($('[name="' + field + '"]').val() === '0' || $('[name="' + field + '"]').val() === '') {
        $('[name="' + field + '"]').removeAttr('name');
        if (field === 'tx_news_pi1[search][subject]') {
            $('[name="tx_news_pi1[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_news_pi1[__trustedProperties]"]').removeAttr('name');
        }
        if (field === 'tx_stratismap_location[keyword]') {
            $('[name="tx_stratismap_location[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__trustedProperties]"]').removeAttr('name');
        }
    }
}

function showSubcat() {
    // var selectedParentCategory = $('#categories').find('option:selected').val();
    // if (selectedParentCategory !== '' && selectedParentCategory !== '0') {
    //     $('.subcat').addClass('hidden').find('select').attr('disabled', true);
    //     var selectedSubCategoryWrapper = $('[data-id="' + selectedParentCategory + '"]');
    //     if (selectedSubCategoryWrapper.length > 0) {
    //         $('.default-subcat').addClass('hidden').find('select').attr('disabled', true);
    //         selectedSubCategoryWrapper.removeClass('hidden').find('select').removeAttr('disabled');
    //     } else {
    //         $('.default-subcat').removeClass('hidden');
    //     }
    // }

    var category = $('#categories'),
        categoryData = $(category).find('option:selected').val(),
        subCategories = $('.subcats'),
        themes = $('.subcats-1'),
        subcat = subCategories.find('.subcat'),
        theme = themes.find('.subcat'),
        themesDefautlSelect = themes.find('.default-subcat'),
        defaultSelect = subCategories.find('.default-subcat');

    if (subCategories.length) {

        if (categoryData !== '0' || categoryData !== '') {

            defaultSelect.addClass('hidden');
            subcat.find('select').attr('disabled', true);
            subcat.addClass('hidden');

            subcat.each(function () {
                var subcatId = $(this).attr('data-id');
                var subcatData = $(this).find('option:selected').val();
                console.log("categoryData: " + categoryData);

                if (categoryData == subcatId) {
                    $(this).removeClass('hidden');
                    $(this).find('select').removeAttr('disabled')
                    theme.addClass('hidden');
                    themesDefautlSelect.addClass('hidden');

                    if (subcatData !== '0' || subcatData !== '') {
                        theme.each(function () {
                            var themeId = $(this).attr('data-id');

                            if (subcatData == themeId) {
                                $(this).removeClass('hidden');
                                $(this).find('select').removeAttr('disabled');
                            }

                            else if (subcatData == '0' || subcatData == '') {
                                themesDefautlSelect.removeClass('hidden');
                            }
                        });
                    }
                }

                else if (categoryData == '0' || categoryData == '') {
                    defaultSelect.removeClass('hidden');
                    subcat.each(function () {
                        var subcatSel = $(this).find('select');
                        subcatSel.find('option:first').attr('selected', true);
                    });

                    if (!defaultSelect.hasClass('hidden')) {
                        themesDefautlSelect.removeClass('hidden');
                        theme.addClass('hidden');
                    }
                }
            });
        }
    }
}

$(document).ready(function () {
    showSubcat();

    $('#categories').change(function () {
        showSubcat();
    });

    var subCatFields = $('.subcat').find('select');

    subCatFields.each(function () {
        $(this).change(function () {
            showSubcat();
        });
    });

});

function catmenu() {
    // report des valeurs des checkbox dans un champ hidden
    if ($('.checkbox.multi').length > 0 && $('input[type="hidden"].getMulti').length > 0) {

        var $input = $('input[type="hidden"].getMulti', '.checkbox.multi');
        var $checkboxes = $(':checkbox', '.checkbox.multi');

        // This function updates the hidden input with selected options
        var updateCategoriesField = function () {

            // Get a list of checked boxes values
            var selectedValues = $checkboxes.filter(':checked').map(function () {
                return this.value;
            }).get().join();

            // Insert new value into hidden field
            $input.val(selectedValues);
        };

        // Bind update on load and checkbox changes
        updateCategoriesField();
        $checkboxes.on('change', updateCategoriesField);
    }

    // coche les checkbox enfants d'une checkbox parente
    if ($('.check_radio_item__sub').length > 0) {
        $('.check_radio_item__sub').parent().find('> input[type="checkbox"]').on('change', function () {
            if ($(this).is(':checked')) {
                $(this).nextAll('.check_radio_item__sub').find('input[type="checkbox"]').prop('checked', true).trigger('change');
            } else {
                $(this).nextAll('.check_radio_item__sub').find('input[type="checkbox"]').prop('checked', false).trigger('change');
            }
        });
        $('.check_radio_item__sub input[type="checkbox"]').on('change', function () {
            if (!$(this).is(':checked') && $(this).parents('.check_radio_item__sub').parent().find('> input[type="checkbox"]').is(':checked')) {
                $(this).parents('.check_radio_item__sub').parent().find('> input[type="checkbox"]').prop('checked', false);
            }
        });

    }
    if ($('.filters').length > 0) {
        // gestion du menu des archives pour news
        if ($('.filters #tx_news_month').length > 0) {
            if ($('#tx_news_month option:selected').length == 1) {
                catmenuYear = $('#tx_news_month option[selected="selected"]').parent('optgroup').attr('label');
                $('input#tx_news_year').val(catmenuYear);
            }
            $('#tx_news_month').change(function () {
                catmenuYear = $(this).find('option:selected').parent('optgroup').attr('label');
                if (catmenuYear == 'undefined') {
                    $('input#tx_news_year').val('');
                } else {
                    $('input#tx_news_year').val(catmenuYear);
                }
            });
        }
        //
        // // cat/sous-cat
        // if ($('.subcats').length > 0) {
        //     showSubcat();
        //     $('#categories').on('change', function () {
        //         showSubcat();
        //     });
        // }

        // action à la validation du formulaire
        $('.filters form').on('submit', function () {

            var secondLevelValue = $('.subcats .subcat:not(".hidden") select').val();
            var thirdLevelValue = $('.subcats-1 .subcat:not(".hidden") select').val();
            var thirdLevelCats = $('.subcats-1 .subcat');
            var secondLevelCats = $('.subcats .subcat');
            var secondLevelCatsSelected = $('.subcats .subcat:not(".hidden")');
            var thirdLevelCatsSelected = $('.subcats-1 .subcat:not(".hidden")');

            if (thirdLevelCats.length > 0 || secondLevelCats.length > 0) {

                if (thirdLevelCatsSelected.length > 0 && thirdLevelValue !== '0' && thirdLevelValue !== '') {
                    $('.hiddenCat').val(thirdLevelValue);
                }

                else if (secondLevelCatsSelected.length > 0 && secondLevelValue !== '0' && secondLevelValue !== '') {
                    $('.hiddenCat').val(secondLevelValue);
                }

                else if ($('#categories').val() !== '0' && $('#categories').val() !== '') {
                    $('.hiddenCat').val($('#categories').val());
                }

                else {
                    $('.hiddenCat').val('');
                }

            }

            else if ($('#categories').val() !== '0' && $('#categories').val() !== '') {
                $('.hiddenCat').val($('#categories').val());
            }

            else {
                $('.hiddenCat').val('');
            }

            // datepicker
            if ($('#datepicker-hidden').length > 0) {
                if ($('#datepicker-hidden').val().length > 0) {
                    var tab = $('#datepicker-hidden').val().split('-');
                    $('#dated').val(tab[2]);
                    $('#datem').val(tab[1]);
                    $('#datey').val(tab[0]);
                }
            }

            // supprime les attributs name si les champs sont vides, pour éviter d'ajouter des paramètres vides dans l'url
            removeNameAttribute('tx_news_pi1[overwriteDemand][period]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][categories]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][day]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][month]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][year]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][datecomplete]');
            removeNameAttribute('tx_news_pi1[search][subject]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][tags]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedLinks]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][related]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedLocations]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][relatedAreas]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][quartier]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][classes]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][sections]');
            removeNameAttribute('tx_news_pi1[overwriteDemand][groupes]');
            removeNameAttribute('tx_simplepoll_polllisting[category]');
            removeNameAttribute('tx_simplepoll_polllisting[status]');
            removeNameAttribute('tx_stratismap_location[search][keywords]');
            removeNameAttribute('tx_stratismap_location[search][categories][]');
        });
    }
}