$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';

    var carouselShortcutMenu = carrousel3({
        container: '.shortcut-menu-gallery',
        mediaDesktop: {
            carouselVisible: 7,
            allowWrap: true
        },
        mediaTablet: {
            carouselVisible: 5,
            allowWrap: true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap: true
        }
    });

    var carouselPartners = carrousel3({
        container: '.partners-gallery',
        mediaDesktop: {
            carouselVisible: 4,
            allowWrap: true
        },
        mediaTablet: {
            carouselVisible: 2,
            allowWrap: true
        },
        mediaSmartphone: {
            carouselVisible: 1,
            allowWrap: true
        }
    });

    // var carouselPartners = carrousel4({
    //     container: '.partners-gallery',
    //     mediaDesktop: {
    //         carouselVisible: 4,
    //         allowWrap: true
    //     },
    //     mediaTablet: {
    //         carouselVisible: 2,
    //         allowWrap: true
    //     },
    //     mediaSmartphone: {
    //         carouselVisible: 1,
    //         allowWrap: true
    //     }
    // });

    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit: 767
        }, {
            label: 'tablet',
            enter: 768,
            exit: 1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit: 10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter: function () {
                myInitFuncSmartphone();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter: function () {
                myInitFuncTablet();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter: function () {
                myInitFuncDesktop();

            },
            exit: function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        //menuStratis(mediaSmartphone); // 190_stratis_menu.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        //carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carouselShortcutMenu(mediaSmartphone);
        carouselPartners(mediaSmartphone);
        //carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        //menuMain1State(mediaSmartphone);
    }

    function myInitFuncTablet() {
        //menuStratis(mediaTablet); // 190_stratis_menu.js
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        //carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        carouselShortcutMenu(mediaTablet);
        carouselPartners(mediaTablet);
        //carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        //menuMain1State(mediaTablet);
    }

    function myInitFuncDesktop() {
        //menuStratis(mediaDesktop); // 190_stratis_menu.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        //carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carouselShortcutMenu(mediaDesktop);
        carouselPartners(mediaDesktop);
        //carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        //menuMain1State(mediaDesktop);
    }

    // Generic functions
    carrousel1Desktop(); // 330_stratis_carrousel1.js
    preloadImages(); // 170_stratis_preload_images.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    enableTimePicker(); // 310_stratis_datetimepicker.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    menuMain(); // 190_stratis_menu_main.js
    search();
    initSlick();

    $(window).on('load', function () {
        sticky();
    });

    function sticky() {
        var
            $win = $(window),
            $doc = $(document);

        $('.js-sticky').each(function () {
            var
                $elem = $(this),
                $parent = $elem.parent(),
                top = getTopCoord(),
                stickyState = false;

            $win
                .on('resize orientationchange', onResize)
                .on('scroll', onScroll);

            function getHeight() {
                return $elem.outerHeight();
            }

            function getTopCoord() {
                return $parent.offset().top;
            }

            function setParentHeight() {
                $parent.css('height', getHeight());
            }

            function unsetParentHeight() {
                $parent.css('height', '');
            }

            function checkScroll() {
                if (!stickyState &&
                    $win.scrollTop() > top &&
                    $win[0].innerWidth > 1279
                ) {
                    activateSticky();
                } else if (
                    stickyState &&
                    ($win.scrollTop() <= top || $win[0].innerWidth < 1278)
                ) {
                    deactivateSticky();
                }
            }

            function activateSticky() {
                stickyState = true;

                $elem.addClass('sticky-active');
                setParentHeight();

                $doc.trigger('stickyActive', [$elem]);
            }

            function deactivateSticky() {
                stickyState = false;

                $elem.removeClass('sticky-active');
                unsetParentHeight();

                $doc.trigger('stickyInactive', [$elem]);
            }

            function onResize() {
                top = getTopCoord();
                checkScroll();
                stickyState ? setParentHeight() : unsetParentHeight();
            }

            function onScroll() {
                checkScroll();
            }
        });
    }

    function search() {
        var
            $doc = $(document),
            $toggle = $('.search-toggle, .my-search'),
            $search = $('.mobile-menu > .tx-solr >  .search-box'),
            $drop = $search.find('.ddm__sub-level'),
            lastState;

        function initState() {
            if ($(window).width() >= 1280) {
                $search.css({'display': 'block'});
                setAriaAttrs();
            } else {
                $search.css({'display': 'none'});
                setAriaAttrs();
            }
        }

        initState();

        $(window).on('resize', function () {
            initState();
        });

        $toggle.on('click', onToggleClick);
        $doc
            .on('stickyActive', onStickyActive)
            .on('stickyInactive', onStickyInactive);

        function show() {
            $search.show();
            setAriaAttrs();
        }

        function hide() {
            $search.hide();
            setAriaAttrs();
        }

        function toggle() {
            isVisible() ? hide() : show();
        }

        function isVisible() {
            return $search.is(':visible');
        }

        function getState() {
            return isVisible() ? 'visible' : 'hidden';
        }

        function setAriaAttrs() {
            if (isVisible()) {
                $drop.attr('aria-hidden', 'false');
                $toggle.attr('aria-expanded', 'true');
            } else {
                $drop.attr('aria-hidden', 'true');
                $toggle.attr('aria-expanded', 'false');
            }
        }

        function onToggleClick(e) {
            e.preventDefault();
            toggle();
        }

        function onStickyActive(e, $elem) {
            if ($elem.is('.header')) {
                lastState = getState();
                hide();
            }
        }

        function onStickyInactive(e, $elem) {
            if ($elem.is('.header') && $(window).width() >= 1280) {
                lastState === 'visible' ? show() : hide();
            }
        }
    }

    $(".fancyboxAlt").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        closeBtn: false,
        helpers: {
            overlay: {}
        },
        padding: 20,
        tpl: {
            wrap: '<div class="fancybox-wrap fancybox-wrap-2" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>'
        },
        afterLoad: function (current, previous) {
            // handle custom close button in inline modal
            if (current.href.indexOf('#') === 0) {
                jQuery(current.href).find('a.close').off('click.fb').on('click.fb', function (e) {
                    e.preventDefault();
                    jQuery.fancybox.close();
                });
            }
        }
    });
});
accessTooltipState(); // 400_stratis_access-tooltip.js
// Slick galleries
function initSlick() {
    $('.slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        dots: true,
        dotsClass: 'slider-paging-number',
        asNavFor: '.slider-nav',
        customPaging: function (slick) {
            return (slick.currentSlide + 1) + '/' + slick.slideCount;
        }
    }).on('afterChange', function (event, slick, currentSlide) {
        // works with 1.7.1+
        $(this).find('*[role="tablist"]').find('li').eq(0).text(slick.options.customPaging.call(this, slick, currentSlide));
    });

    $('.slider-nav').slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        asNavFor: '.slider',
        dots: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ],
    });
}

$(window).on('load', function () {
    var
        $list = $('.social-list'),
        $filterBtns = $('.filter-social [data-filter]');

    $list.isotope({
        itemSelector: 'li',
        masonry: {}
    });

    filter('.social-list-all');
    toggleActive($filterBtns.eq(0));

    $('.filter-social').on('click', 'a', function (e) {
        e.preventDefault();

        var $elem = $(this);

        filter($elem.attr('data-filter'));
        toggleActive($elem);
    });

    function filter(val) {
        $list.isotope({
            filter: val
        });
    }

    function toggleActive($elem) {
        deactivateAll();
        makeActive($elem);
    }

    function makeActive($elem) {
        $elem.addClass('active');
    }

    function deactivateAll() {
        $filterBtns.removeClass('active');
    }

    // Location block open-close
    $(".block-location .ddm-opener").click(function () {
        $(".block-location").toggleClass("popup--active");
    });

    // Location block open-close
    $(".block-location .close-window").click(function () {
        $(".block-location").toggleClass("map-hide");
    });

    // Fixed menu open-close
    $(".fixed-menu .fixed-menu_opener").click(function () {
        $(".fixed-menu").toggleClass("fixed-menu--active");
        var text_el = $(this).find('.fixed-menu_text');
        if ($('.fixed-menu').hasClass('fixed-menu--active')) {
            $('.fixed-menu_box').find('a, input, select, button').attr('tabindex', 0);
        } else {
            $('.fixed-menu_box').find('a, input, select, button').attr('tabindex', -1);
        }
        text_el.text(function (i, text) {
            return text === "Réduit En 1 clic" ? "Développé En 1 clic" : "Réduit En 1 clic";
        });
        var text_e = $(this).find('.fixed-menu_opener');
        text_e.attr(function (i, text) {
            text.attr('title', 'Développé En 1 clic');
        })
    });
    $('.search-toggle').click(function () {
        var el = $(this);
        if (el.attr('aria-expanded') == 'true') {
            el.attr('title', 'Fermer la recherche');
        }
        else {
            el.attr('title', 'Ouvrir la recherche');
        }
    });
});
